export const POSTCARDS = [
  {
    "id": 0,
    "date": "",
    "photos": ['photos/turtle_0.jpeg'],
    "title": "",
    "from": "",
    "tags": ["turtle"]
},{
    "id": 1,
    "date": "",
    "photos": ['photos/turtle_1.jpeg'],
    "title": "",
    "from": "",
    "tags": ["turtle"]
},{
    "id": 2,
    "date": "",
    "photos": ['photos/turtle_2.jpeg'],
    "title": "",
    "from": "",
    "tags": ["turtle"]
},{
    "id": 3,
    "date": "",
    "photos": ['photos/turtle_3.jpeg'],
    "title": "",
    "from": "",
    "tags": ["turtle"]
},{
    "id": 4,
    "date": "",
    "photos": ['photos/turtle_4.jpeg'],
    "title": "",
    "from": "",
    "tags": ["turtle"]
},{
    "id": 5,
    "date": "",
    "photos": ['photos/turtle_5.jpeg'],
    "title": "",
    "from": "",
    "tags": ["turtle"]
},{
    "id": 6,
    "date": "",
    "photos": ['photos/turtle_6.jpeg'],
    "title": "",
    "from": "",
    "tags": ["turtle"]
},{
    "id": 7,
    "date": "",
    "photos": ['photos/turtle_7.jpeg'],
    "title": "",
    "from": "",
    "tags": ["turtle"]
},{
    "id": 8,
    "date": "",
    "photos": ['photos/turtle_8.jpeg'],
    "title": "",
    "from": "",
    "tags": ["turtle"]
},{
    "id": 9,
    "date": "",
    "photos": ['photos/turtle_9.jpeg'],
    "title": "",
    "from": "",
    "tags": ["turtle"]
},{
    "id": 10,
    "date": "",
    "photos": ['photos/turtle_10.jpeg'],
    "title": "",
    "from": "",
    "tags": ["turtle"]
},{
    "id": 11,
    "date": "",
    "photos": ['photos/turtle_11.jpeg'],
    "title": "",
    "from": "",
    "tags": ["turtle"]
},{
    "id": 12,
    "date": "",
    "photos": ['photos/turtle_12.jpeg'],
    "title": "",
    "from": "",
    "tags": ["turtle"]
},{
    "id": 13,
    "date": "",
    "photos": ['photos/turtle_13.jpeg'],
    "title": "",
    "from": "",
    "tags": ["turtle"]
},{
    "id": 14,
    "date": "",
    "photos": ['photos/turtle_14.jpeg'],
    "title": "",
    "from": "",
    "tags": ["turtle"]
},{
    "id": 15,
    "date": "",
    "photos": ['photos/turtle_15.jpeg'],
    "title": "",
    "from": "",
    "tags": ["turtle"]
},{
    "id": 16,
    "date": "",
    "photos": ['photos/turtle_16.jpeg'],
    "title": "",
    "from": "",
    "tags": ["turtle"]
},{
    "id": 17,
    "date": "",
    "photos": ['photos/turtle_17.jpeg'],
    "title": "",
    "from": "",
    "tags": ["turtle"]
},{
    "id": 18,
    "date": "",
    "photos": ['photos/marine_life_18.jpeg'],
    "title": "",
    "from": "",
    "tags": ["marine_life"]
},{
    "id": 19,
    "date": "",
    "photos": ['photos/marine_life_19.jpeg'],
    "title": "",
    "from": "",
    "tags": ["marine_life"]
},{
    "id": 20,
    "date": "",
    "photos": ['photos/marine_life_20.jpeg'],
    "title": "",
    "from": "",
    "tags": ["marine_life"]
},{
    "id": 21,
    "date": "",
    "photos": ['photos/marine_life_21.jpeg'],
    "title": "",
    "from": "",
    "tags": ["marine_life"]
},{
    "id": 22,
    "date": "",
    "photos": ['photos/marine_life_22.jpeg'],
    "title": "",
    "from": "",
    "tags": ["marine_life"]
},{
    "id": 23,
    "date": "",
    "photos": ['photos/marine_life_23.jpeg'],
    "title": "",
    "from": "",
    "tags": ["marine_life"]
},{
    "id": 24,
    "date": "",
    "photos": ['photos/marine_life_24.jpeg'],
    "title": "",
    "from": "",
    "tags": ["marine_life"]
},{
    "id": 25,
    "date": "",
    "photos": ['photos/marine_life_25.jpeg'],
    "title": "",
    "from": "",
    "tags": ["marine_life"]
},{
    "id": 26,
    "date": "",
    "photos": ['photos/marine_life_26.jpeg'],
    "title": "",
    "from": "",
    "tags": ["marine_life"]
},{
    "id": 27,
    "date": "",
    "photos": ['photos/marine_life_27.jpeg'],
    "title": "",
    "from": "",
    "tags": ["marine_life"]
},{
    "id": 28,
    "date": "",
    "photos": ['photos/marine_life_28.jpeg'],
    "title": "",
    "from": "",
    "tags": ["marine_life"]
},{
    "id": 29,
    "date": "",
    "photos": ['photos/marine_life_29.jpeg'],
    "title": "",
    "from": "",
    "tags": ["marine_life"]
},{
    "id": 30,
    "date": "",
    "photos": ['photos/marine_life_30.jpeg'],
    "title": "",
    "from": "",
    "tags": ["marine_life"]
},{
    "id": 31,
    "date": "",
    "photos": ['photos/marine_life_31.jpeg'],
    "title": "",
    "from": "",
    "tags": ["marine_life"]
},{
    "id": 32,
    "date": "",
    "photos": ['photos/marine_life_32.jpeg'],
    "title": "",
    "from": "",
    "tags": ["marine_life"]
},{
    "id": 33,
    "date": "",
    "photos": ['photos/marine_life_33.jpeg'],
    "title": "",
    "from": "",
    "tags": ["marine_life"]
},{
    "id": 34,
    "date": "",
    "photos": ['photos/marine_life_34.jpeg'],
    "title": "",
    "from": "",
    "tags": ["marine_life"]
},{
    "id": 35,
    "date": "",
    "photos": ['photos/marine_life_35.jpeg'],
    "title": "",
    "from": "",
    "tags": ["marine_life"]
},{
    "id": 36,
    "date": "",
    "photos": ['photos/marine_life_36.jpeg'],
    "title": "",
    "from": "",
    "tags": ["marine_life"]
},
{
    "id": 37,
    "date": "",
    "photos": ['photos/turtle_37.jpeg'],
    "title": "",
    "from": "",
    "tags": ["turtle"]
},{
    "id": 38,
    "date": "",
    "photos": ['photos/turtle_38.jpeg'],
    "title": "",
    "from": "",
    "tags": ["turtle"]
},{
    "id": 39,
    "date": "",
    "photos": ['photos/turtle_39.jpeg'],
    "title": "",
    "from": "",
    "tags": ["turtle"]
},{
    "id": 40,
    "date": "",
    "photos": ['photos/turtle_40.jpeg'],
    "title": "",
    "from": "",
    "tags": ["turtle"]
},{
    "id": 41,
    "date": "",
    "photos": ['photos/turtle_41.jpeg'],
    "title": "",
    "from": "",
    "tags": ["turtle"]
},{
    "id": 42,
    "date": "",
    "photos": ['photos/turtle_42.jpeg'],
    "title": "",
    "from": "",
    "tags": ["turtle"]
},{
    "id": 43,
    "date": "",
    "photos": ['photos/turtle_43.jpeg'],
    "title": "",
    "from": "",
    "tags": ["turtle"]
},{
    "id": 44,
    "date": "",
    "photos": ['photos/marine_life_44.jpeg'],
    "title": "",
    "from": "",
    "tags": ["marine_life"]
},{
    "id": 45,
    "date": "",
    "photos": ['photos/marine_life_45.jpeg'],
    "title": "",
    "from": "",
    "tags": ["marine_life"]
},{
    "id": 46,
    "date": "",
    "photos": ['photos/marine_life_46.jpeg'],
    "title": "",
    "from": "",
    "tags": ["marine_life"]
},{
    "id": 47,
    "date": "",
    "photos": ['photos/marine_life_47.jpeg'],
    "title": "",
    "from": "",
    "tags": ["marine_life"]
},
{
    "id": 48,
    "date": "",
    "photos": ['photos/turtle_48.jpeg'],
    "title": "",
    "from": "",
    "tags": ["turtle"]
},{
    "id": 49,
    "date": "",
    "photos": ['photos/loupaper_49.jpeg'],
    "title": "",
    "from": "",
    "tags": ["loupaper"]
},{
    "id": 50,
    "date": "",
    "photos": ['photos/marine_life_50.jpeg'],
    "title": "",
    "from": "",
    "tags": ["marine_life"]
},
{
    "id": 51,
    "date": "",
    "photos": ['photos/turtle_51.jpeg'],
    "title": "",
    "from": "",
    "tags": ["turtle"]
},{
    "id": 52,
    "date": "",
    "photos": ['photos/turtle_52.jpeg'],
    "title": "",
    "from": "",
    "tags": ["turtle"]
},{
    "id": 53,
    "date": "",
    "photos": ['photos/turtle_53.jpeg'],
    "title": "",
    "from": "",
    "tags": ["turtle"]
},{
    "id": 54,
    "date": "",
    "photos": ['photos/loupaper_54.jpeg'],
    "title": "",
    "from": "",
    "tags": ["loupaper"]
},{
    "id": 55,
    "date": "",
    "photos": ['photos/marine_life_55.jpeg'],
    "title": "",
    "from": "",
    "tags": ["marine_life"]
},{
    "id": 56,
    "date": "",
    "photos": ['photos/marine_life_56.jpeg'],
    "title": "",
    "from": "",
    "tags": ["marine_life"]
},{
    "id": 57,
    "date": "",
    "photos": ['photos/marine_life_57.jpeg'],
    "title": "",
    "from": "",
    "tags": ["marine_life"]
},{
    "id": 58,
    "date": "",
    "photos": ['photos/marine_life_58.jpeg'],
    "title": "",
    "from": "",
    "tags": ["marine_life"]
},{
    "id": 59,
    "date": "",
    "photos": ['photos/marine_life_59.jpeg'],
    "title": "",
    "from": "",
    "tags": ["marine_life"]
},
{
    "id": 60,
    "date": "",
    "photos": ['photos/turtle_60.jpeg'],
    "title": "",
    "from": "",
    "tags": ["turtle"]
},{
    "id": 61,
    "date": "",
    "photos": ['photos/loupaper_61.jpeg'],
    "title": "",
    "from": "",
    "tags": ["loupaper"]
},{
    "id": 62,
    "date": "",
    "photos": ['photos/loupaper_62.jpeg'],
    "title": "",
    "from": "",
    "tags": ["loupaper"]
}
]