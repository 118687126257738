import { useState } from 'react';
import * as React from "react";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

import { POSTCARDS } from "../src/data";

export default function App(){
  const [selectedCards, setSelectedCards] = useState(
    POSTCARDS.filter(postcard => postcard.tags.includes("turtle"))
  );

  function filterPostcards(tag) {
      if(tag == ""){
        setSelectedCards(POSTCARDS);
      } else {
        setSelectedCards(POSTCARDS.filter(postcard => postcard.tags.includes(tag)));
      }
  }

  return(
    <div id="app-container">
      <div id="header">
        <div id="title">
          <h1>Welcome to my postcard gallery</h1>
        </div>
        <div id="tag-container" className="form">
          <label style={{marginRight: '20px'}}><input type="radio" name="tagPicker" defaultChecked onClick = {() => filterPostcards("turtle")}/> Turtles</label>
          <label style={{marginRight: '20px'}}><input type="radio" name="tagPicker"  onClick = {() => filterPostcards("marine_life")}/> Other marine life</label> 
          <label><input type="radio" name="tagPicker"  onClick = {() => filterPostcards("loupaper")}/> LouPaper</label>
          </div>
      </div>
      <Gallery postcardSelection={selectedCards}/>
    </div>
  )
}

function Gallery({postcardSelection}) {
  const [index, setIndex] = React.useState(-1);

  return (
    <div className="gallery">
      <div className="postcard-display">
        {postcardSelection.map((postcard, i) => (
          <PostcardPreview
            key={postcard.id} 
            url={postcard.photos[0]} 
            onClickHandler={() => setIndex(i)}
          />
        ))}
      </div>
      <Lightbox
        index={index}
        open={index >= 0}
        close={() => setIndex(-1)}
        slides={postcardSelection.map(postcard => ({ src: postcard.photos[0] }))}
      />
    </div>
  );
}

function PostcardPreview({ url, onClickHandler }) {
  return (
    <div className="postcard">
      <img src={url} alt="Postcard" onClick={onClickHandler} />
    </div>
  );
}
